import axios from "axios"
import {
    useState
} from 'react';
import {
    toast
} from 'react-toastify';
import {
    datadogLogs
} from '@datadog/browser-logs'

const useAdvancedCleanerMGT = () => {
    const [complaints, setComplaints] = useState([])
    const [reviews, setReviews] = useState([])
    const [checklists, setChecklists] = useState([])
    const [loading, setLoading] = useState(true)
    const [cleaners, setCleaners] = useState([])
    const [workOrders, setWorkOrders] = useState([])
    const BASE_URL = process.env.REACT_APP_ADVANCED_CLEANER;

    const fetchComplaints = async () => {
        try {
            const BASE_URL = process.env.REACT_APP_ADVANCED_CLEANER;
            const response = await axios.get(`${BASE_URL}complaints`);
            console.log("COMPLAINTS", response.data);
            setComplaints(response.data);
            // toast.success("Fetched complaints successfully")
        } catch (error) {
            toast.error("Error fetching complaints");
            datadogLogs.logger.error('Error occurred', {}, error)
        } finally {
            setLoading(false)
        }
    };

    const fetchChecklists = async () => {
        try {
            const res = await axios.get(`${BASE_URL}checklists`);
            setChecklists(res.data.checklists)
            // toast.success("Fetched checklists successfully")
        } catch (error) {
            toast.error("Error fetching checklists...")
        } finally {
            setLoading(false)
        }
    };

    const fetchCleaners = async () => {
        try {
            const res = await axios.get(`${BASE_URL}cleaners`);
            setCleaners(res.data.cleanerProfiles || []); // Ensure data is an array
        } catch (error) {
            console.log(error);
            setCleaners([]); // Set to an empty array in case of error
            datadogLogs.logger.error('Error occurred', {}, error)
            toast.error("Error fetching cleaners")
        } finally {
            setLoading(false); // Ensure loading state is turned off after data fetch
        }
    };

    const fetchReviews = async (cleanerId) => {
        try {
            const res = await axios.post(`${BASE_URL}commendationuser`, {
                id: cleanerId,
            });
            setReviews(res.data.data || []); // Ensure data is an array
            // toast.success("Fetched reviews successfully")
        } catch (error) {
            console.log("Error fetching reviews:", error);
            setReviews([]); // Set to an empty array in case of error
            datadogLogs.logger.error('Error occurred', {}, error)
            toast.error("Error fetching checklists")

        } finally {
            setLoading(false); // Ensure loading state is turned off after data fetch
        }
    };

    const fetchWorkOrders = async () => {
        try {
            const res = await axios.get(`${BASE_URL}workorder`);
            if (res.status === 200) {
                const data = res.data.data;
                console.log("Fetched work orders: ", data);
                setWorkOrders(Array.isArray(data) ? data : []);
                // toast.success("Fetched work orders successfully")
            }
        } catch (error) {
            console.log("Error fetching work orders: ", error);
            setWorkOrders([]);
            datadogLogs.logger.error('Error occurred', {}, error)
        } finally {
            setLoading(false);
        }
    };

    const createWorkOrder = async (newWorkOrder) => {
        try {
            const res = await axios.post(`${BASE_URL}workorder`, newWorkOrder);
            if (res) {
                console.log("Work order created: ", res);
                toast.success("Work Order Created Successfully")
            }
        } catch (error) {
            toast.error("Error creating work order...")
            datadogLogs.logger.error('Error occurred', {}, error)
        }
    }

    const addReview = async (newReview) => {
        try {
            const res = await axios.post(`${BASE_URL}commendations`, newReview);
            if (res.data.success === true || res.data.data) {
                toast.success("Added review successfully")
            }
        } catch (error) {
            toast.error("Something went wrong, please try again later")
            datadogLogs.logger.error('Error occurred', {}, error)
        }
    }


    const addCleaner = async (newCleaner) => {
        try {
            const res = await axios.post(`${BASE_URL}cleaners`, newCleaner);
            if (res.data) {
                toast.success("Added User successfully")
            } else {
                toast.error("Invalid parameters for creating a new user.")
            }
        } catch (error) {
            toast.error("Something went wrong, please try again later.")
            datadogLogs.logger.error('Error occurred', {}, error)
        }
    }

    return {
        complaints,
        fetchComplaints,
        checklists,
        fetchChecklists,
        fetchCleaners,
        cleaners,
        loading,
        reviews,
        fetchReviews,
        workOrders,
        fetchWorkOrders,
        createWorkOrder,
        addReview,
        addCleaner
    }
}

export default useAdvancedCleanerMGT