import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Rating
} from '@mui/material';
import useAdvancedCleanerMGT from 'Hooks/useAdvancedCleanerMGT';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddReview from './AddReview';
import { useParams } from 'react-router';
import BackButton from 'component/BackButton';

const ReviewsHome = () => {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const { fetchReviews, fetchCleaners, reviews, cleaners, loading } = useAdvancedCleanerMGT();

  useEffect(() => {
    fetchCleaners();
    fetchReviews(id);
  }, [fetchCleaners, fetchReviews, id]);

  const filteredReviews = reviews.filter(review => review.text.toLowerCase().includes(searchQuery.toLowerCase()));

  const getCleanerName = id => {
    const cleaner = cleaners.find(c => c._id === id);
    return cleaner ? `${cleaner.firstname} ${cleaner.lastname}` : 'Unknown Cleaner';
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <BackButton/>
      <Typography variant="h4" color="primary" gutterBottom>
        Reviews for {getCleanerName(id)}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField variant="outlined" label="Search reviews" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} fullWidth />
        <Button variant="contained" color="primary" onClick={() => setVisible(true)} sx={{ ml: 2 }}>
          Add a review
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rating</TableCell>
              <TableCell>Review</TableCell>
              <TableCell>Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReviews.length > 0 ? (
              filteredReviews.map(item => (
                <TableRow key={item._id}>
                  <TableCell>
                    <Rating value={item.rating} readOnly />
                  </TableCell>
                  <TableCell>{item.text}</TableCell>
                  <TableCell>
                    {item.media ? <img src={item.media} alt="Review" style={{ width: 50, height: 50, borderRadius: '50%' }} /> : 'No Image'}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Reviews Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddReview selectedCleaner={id} visible={visible} onClose={() => setVisible(false)} />
      <ToastContainer />
    </Box>
  );
};

export default ReviewsHome;
