import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";

const CreateWorkOrderModal = ({ visible, onClose, onCreateWorkOrder }) => {
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState("pending");
  const [selectedCleanerId, setSelectedCleanerId] = useState("");
  const {
    cleaners,
    fetchCleaners,
    loading,
  } = useAdvancedCleanerMGT();

  useEffect(() => {
    fetchCleaners();
  }, [fetchCleaners]);

  const handleCreateWorkOrder = async () => {
    try {
      const today = new Date().toISOString().split("T")[0];
      const formattedStartTime = `${today}T${startTime}:00Z`;
      const formattedEndTime = `${today}T${endTime}:00Z`;

      const newWorkOrder = {
        description,
        location,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        status,
        cleaner: selectedCleanerId,
      };

      await onCreateWorkOrder(newWorkOrder);
      Alert.alert("Success", "Work Order Created successfully");
      onClose();
      setDescription("");
      setLocation("");
      setStartTime("");
      setEndTime("");
      setStatus("pending");
      setSelectedCleanerId("");
    } catch (error) {
      console.error("Error creating work order:", error);
    }
  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Create Work Order</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Start Time</InputLabel>
              <Select
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              >
                {Array.from({ length: 24 }, (_, i) => {
                  const time = `${i < 10 ? "0" : ""}${i}:00`;
                  return (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>End Time</InputLabel>
              <Select
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              >
                {Array.from({ length: 24 }, (_, i) => {
                  const time = `${i < 10 ? "0" : ""}${i}:00`;
                  return (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Cleaner</InputLabel>
              <Select
                value={selectedCleanerId}
                onChange={(e) => setSelectedCleanerId(e.target.value)}
              >
                {cleaners.map((cleaner) => (
                  <MenuItem key={cleaner._id} value={cleaner._id}>
                    {cleaner.firstname} {cleaner.lastname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateWorkOrder}
          color="primary"
          disabled={loading}
        >
          Create Work Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorkOrderModal;
