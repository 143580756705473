import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  TablePagination
} from "@mui/material";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";
import AddUserModal from "./AddUserModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UsersHome = () => {
  const { cleaners, fetchCleaners, addCleaner, loading } = useAdvancedCleanerMGT();
  const [filter, setFilter] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCleaners();
  }, []);

  const filteredCleaners = cleaners?.filter(
    (cleaner) =>
      cleaner.firstname.toLowerCase().includes(filter.toLowerCase()) ||
      cleaner.lastname.toLowerCase().includes(filter.toLowerCase())
  );

  const handleAddCleaner = async (newCleaner) => {
    await addCleaner(newCleaner);
    setModalVisible(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Welcome Partner!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Keep track of all Cleaning Activities, Work Orders, Cleaners, and Inspectors.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h5" color="primary" gutterBottom>
          Cleaners
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          List of cleaners assigned to your organization
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalVisible(true)}
          sx={{ marginBottom: 2 }}
        >
          Add New
        </Button>
        <TextField
          variant="outlined"
          label="Filter by first name or last name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Standard Wage</TableCell>
              <TableCell>Overtime Wage</TableCell>
              <TableCell>Reviews</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCleaners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{`${item.firstname} ${item.lastname}`}</TableCell>
                <TableCell>{item.standardWage}</TableCell>
                <TableCell>{item.overtimeWage}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/dashboard/cleaner-management/reviews/${item._id}`)}
                  >
                    View Reviews
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCleaners.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <ToastContainer />

      <AddUserModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onAddCleaner={handleAddCleaner}
      />
    </Box>
  );
};

export default UsersHome;
