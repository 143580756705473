import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router';
const BackButton = () => {
    const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };
  return (
    <IconButton onClick={handleBackClick} sx={{ marginBottom: 2 }}>
      <ArrowBackIos />
    </IconButton>
  );
};

export default BackButton;
