import React, { useState } from 'react';
import { Box, Typography, Modal, TextField, Button, CircularProgress } from '@mui/material';
import useAdvancedCleanerMGT from 'Hooks/useAdvancedCleanerMGT';

const AddUserModal = ({ visible, onClose }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [standardWage, setStandardWage] = useState('');
  const [overtimeWage, setOvertimeWage] = useState('');
  const { addCleaner } = useAdvancedCleanerMGT();
  const [userLoading, setUserLoading] = useState(false);

  const handleAddCleaner = async () => {
    setUserLoading(true);
    const newCleaner = {
      firstname,
      lastname,
      standardWage: parseFloat(standardWage),
      overtimeWage: parseFloat(overtimeWage)
    };
    await addCleaner(newCleaner);
    onClose();
    setFirstname('');
    setLastname('');
    setStandardWage('');
    setOvertimeWage('');
    setUserLoading(false);
  };

  return (
    <Modal open={visible} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={styles.modalContainer}>
        <Box sx={styles.modalContent}>
          <Typography id="modal-title" variant="h6" component="h2">
            Add New Cleaner
          </Typography>
          <TextField
            label="First Name"
            placeholder="First Name"
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            placeholder="Last Name"
            value={lastname}
            onChange={e => setLastname(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Standard Wage"
            placeholder="Standard Wage"
            value={standardWage}
            onChange={e => setStandardWage(e.target.value)}
            type="number"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Overtime Wage"
            placeholder="Overtime Wage"
            value={overtimeWage}
            onChange={e => setOvertimeWage(e.target.value)}
            type="number"
            fullWidth
            margin="normal"
          />
          <Box sx={styles.buttonContainer}>
            <Button onClick={onClose} variant="contained" color="error" sx={styles.button}>
              Cancel
            </Button>
            <Button
              onClick={handleAddCleaner}
              variant="contained"
              color="primary"
              sx={styles.button}
              disabled={firstname == '' || lastname == ''}
            >
              {userLoading ? <CircularProgress size={24} /> : 'Add Cleaner'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'rgba(0, 0, 0, 0.5)',
    p: 4
  },
  modalContent: {
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 2,
    boxShadow: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2
  },
  button: {
    flex: 1,
    mx: 1
  }
};

export default AddUserModal;
