import React, { lazy } from 'react';
import Loadable from 'component/Loadable';
const Lessons = Loadable(lazy(() => import('views/Admin/Learning/Lessons')));
const LessonFile = Loadable(lazy(() => import('views/Admin/Learning/LessonFile')));
const AssignPermission = Loadable(lazy(() => import('views/Admin/Permissions/AssignPermissions')));
const FacilityTimer = Loadable(lazy(() => import('views/Admin/FacilityTimer/FacilityTimer')));
const ComingSoon = Loadable(lazy(() => import('views/Admin/FacilityRelease/ComingSoon')));
const FacilityRelease = Loadable(lazy(() => import('views/Admin/FacilityRelease/FacilityRelease')));
const FacilityTimerDetails = Loadable(lazy(() => import('views/Admin/FacilityTimer/FacilityTimerDetails')));
const FacilityReleaseDetails = Loadable(lazy(() => import('views/Admin/FacilityRelease/FacilityReleaseDetails')));
const CreatePermissions = Loadable(lazy(() => import('views/Admin/Permissions/CreatePermissions')));
const CreateRole = Loadable(lazy(() => import('views/Admin/Roles/CreateRole')));
const AssignRole = Loadable(lazy(() => import('views/Admin/Roles/AssignRole')));
const StaffRoles = Loadable(lazy(() => import('views/Admin/Roles/StaffRoles')));
const RevokeRole = Loadable(lazy(() => import('views/Admin/Roles/RevokeRole')));
const ViewRolePermissions = Loadable(lazy(() => import('views/Admin/Roles/ViewRolePermissions')));
const Roles = Loadable(lazy(() => import('views/Admin/Roles/Roles')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const AddTask = Loadable(lazy(() => import('views/Admin/Work Order/AddTask')));
const TaskDetails = Loadable(lazy(() => import('views/Admin/Work Order/TaskDetails')));
const WorkOrder = Loadable(lazy(() => import('views/Admin/Work Order/WorkOrder')));
const WorkOrderDetails = Loadable(lazy(() => import('views/Admin/Work Order/WorkOrderDetails')));
const WorkOrderPage = Loadable(lazy(() => import('views/Admin/Work Order/WorkOrderPage')));
const SelectInspector = Loadable(lazy(() => import('views/Admin/Work Order/SelectInspector')));
const FacilityWorkOrder = Loadable(lazy(() => import('views/Admin/Work Order/FacilityWorkOrder')));
const WorkOrderFacility = Loadable(lazy(() => import('views/Admin/Work Order/WorkOrderFacility')));
const WorkOrderOrder = Loadable(lazy(() => import('views/Admin/Work Order/WorkOrderOrder')));
const CreateWorkOrder = Loadable(lazy(() => import('views/Admin/Work Order/CreateWorkOrder')));
const Room = Loadable(lazy(() => import('views/Admin/Rooms/Rooms')));
const AddRoom = Loadable(lazy(() => import('views/Admin/Rooms/AddRoom')));
const RoomDetails = Loadable(lazy(() => import('views/Admin/Rooms/RoomDetails')));
const TaskDetailsPage = Loadable(lazy(() => import('views/Admin/Work Order/TaskDetailsPage')));
const RoomView = Loadable(lazy(() => import('views/Admin/Rooms/RoomView')));
const Contact = Loadable(lazy(() => import('views/Admin/Contact/Contact')));
const Inventory = Loadable(lazy(() => import('views/Admin/Inventory/index')));
const Locations = Loadable(lazy(() => import('views/Admin/Locations/Locations')));
const WorkHistory = Loadable(lazy(() => import('views/Admin/Work History/WorkHistory')));
const WorkHistoryDetails = Loadable(lazy(() => import('views/Admin/Work History/Room Work History/RoomHistoryDetails')));
const CleanerHistory = Loadable(lazy(() => import('views/Admin/Work History/Cleaner Work History/CleanerHistory')));
const CleanerHistoryDetails = Loadable(lazy(() => import('views/Admin/Work History/Cleaner Work History/CleanerHistoryDetails')));
const InspectorHistory = Loadable(lazy(() => import('views/Admin/Work History/Inspector Work History/InspectorHistory')));
const InspectorHistoryDetails = Loadable(lazy(() => import('views/Admin/Work History/Inspector Work History/InspectorHistoryDetails')));
const RoomHistory = Loadable(lazy(() => import('views/Admin/Work History/Room Work History/RoomHistory')));
const Evidence = Loadable(lazy(() => import('views/Admin/Evidence/Evidence')));
const ViewEvidence = Loadable(lazy(() => import('views/Admin/Evidence/ViewEvidence')));
const Users = Loadable(lazy(() => import('views/Admin/Users/Users')));
const DashboardDefault = Loadable(lazy(() => import('../views/Admin/Dashboard')));
const Permissions = Loadable(lazy(() => import('../views/Admin/Permissions/Permissions')));
const LandingPage = Loadable(lazy(() => import('../views/Admin/Dashboard/Landing Page/index')));
const SanitationSchedule = Loadable(lazy(() => import('../views/Admin/Dashboard/Facility Sanitation Schedule/index')));
const SanitationDetails = Loadable(lazy(() => import('../views/Admin/Dashboard/Facility Sanitation Schedule/SanitationDetails')));
const CleaningTimer = Loadable(lazy(() => import('../views/Admin/Dashboard/Facility Cleaning Timer/timer/CleaningTimer')));
const Learning = Loadable(lazy(() => import('../views/Admin/Learning/index')));
const AttendanceHome = Loadable(lazy(() => import('../views/Admin/Attendance')));
const CreateTraining = Loadable(lazy(() => import('../views/Admin/Learning/CreateTraining')));
const EditTraining = Loadable(lazy(() => import('../views/Admin/Learning/EditTraining')));
const ViewTraining = Loadable(lazy(() => import('../views/Admin/Learning/ViewTraining')));
const ViewCourse = Loadable(lazy(() => import('../views/Admin/Learning/ViewCourse')));
const AddChemicalMix = Loadable(lazy(() => import('views/Admin/Chemical Calculator/AddChemicalMix')));
const EditChemicalMix = Loadable(lazy(() => import('views/Admin/Chemical Calculator/EditChemicalMix')));
const ViewChemicalMix = Loadable(lazy(() => import('views/Admin/Chemical Calculator/ViewChemicalMix')));

import AddCourse from 'views/Admin/Learning/AddCourse';
import ChemicalCalculatorHome from 'views/Admin/Chemical Calculator';
import AttendanceHistory from 'views/Admin/Attendance/AttendanceHistory';
import AdvancedCleanerMgtHome from 'views/Admin/Advanced Cleaner Management';
import ReviewsHome from 'views/Admin/Advanced Cleaner Management/Users/ReviewsHome';

// Cleaner
const CleanerLocationDetails = Loadable(lazy(() => import('../views/Cleaner/Dashboard/CleanerLocationDetails')));
const CleanerFacilityCleaningItems = Loadable(lazy(() => import('../views/Cleaner/Dashboard/CleanerFacilityCleaningItems')));
const SaveCleaningItems = Loadable(lazy(() => import('../views/Cleaner/Dashboard/SaveCleaningItems')));
const CleanerSummary = Loadable(lazy(() => import('../views/Cleaner/Dashboard/CleanerSummary')));
const CleanerTimer = Loadable(lazy(() => import('../views/Cleaner/Dashboard/CleanerTimer')));
const NewCleanerTimer = Loadable(lazy(() => import('../views/Cleaner/Dashboard/NewCleanerTimer')));
const RequestCleaningItems = Loadable(lazy(() => import('../views/Cleaner/Dashboard/RequestCleaningItems')));
const Timer = Loadable(lazy(() => import('../views/Cleaner/Timer/CleanerTimer')));
const LearningHome = Loadable(lazy(() => import('views/Cleaner/Learning')));
const ChatHome = Loadable(lazy(() => import('../views/Messages')));
const UserChatList = Loadable(lazy(() => import('views/Messages/UserChatList')));
const ChatBox = Loadable(lazy(() => import('views/Messages/ChatBox')));
// Inspector
const InspectorLocationDetails = Loadable(lazy(() => import('../views/Inspector/Dashboard/InspectorLocationDetails')));
const InspectorDashboard = Loadable(lazy(() => import('views/Inspector/Dashboard/InspectorDashboard')));
const InspectorFacilityCleaningItems = Loadable(lazy(() => import('../views/Inspector/Dashboard/InspectorFacilityCleaningItems')));
const InspectorTimer = Loadable(lazy(() => import('../views/Inspector/Dashboard/InspectorTimer')));
const CloseWorkOrder = Loadable(lazy(() => import('../views/Inspector/Dashboard/CloseWorkOrder')));
const InspectorRequests = Loadable(lazy(() => import('../views/Inspector/Requests/InspectorRequests')));
const InspectorRequestsDetails = Loadable(lazy(() => import('../views/Inspector/Requests/RequestDetails')));
const InspectorFacilityTimer = Loadable(lazy(() => import('views/Inspector/FacilityTimer/FacilityTimer')));
const InspectorFacilityDetails = Loadable(lazy(() => import('views/Inspector/Dashboard/InspectorFacilityDetails')));
const InspectorComingSoon = Loadable(lazy(() => import('views/Inspector/FacilityRelease/ComingSoon')));
const InspectorFacilityRelease = Loadable(lazy(() => import('views/Inspector/FacilityRelease/FacilityRelease')));
const InspectorFacilityTimerDetails = Loadable(lazy(() => import('views/Inspector/FacilityTimer/FacilityTimerDetails')));
const InspectorFacilityReleaseDetails = Loadable(lazy(() => import('views/Inspector/FacilityRelease/FacilityReleaseDetails')));
const ClockInCard = Loadable(lazy(() => import('views/Cleaner/Attendance')));
const UserProfile = Loadable(lazy(() => import('views/Admin/Users/ViewUser')));
const TeamOverview = Loadable(lazy(()=>import('views/Admin/Learning/Team')));
const TrainingOverview = Loadable(lazy(()=> import('views/Admin/Learning/TrainingOverview')));
const TrainingCategory = Loadable(lazy(()=> import('views/Admin/Learning/TrainingCategory')));
const SetTraining = Loadable(lazy(()=> import('views/Admin/Learning/SetTraining')));
// ==============================|| MAIN ROUTES ||============================== //
const role = localStorage.getItem('role');
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <LandingPage />,
      children: [
        { path: '', index: true, element: <DashboardDefault /> },
        { path: 'sanitation-schedule', element: <SanitationSchedule /> },

        { path: 'cleaning-timer', element: <CleaningTimer /> }
      ]
    },
    { path: '/user-profile', element: <UserProfile /> },
    { path: '/dashboard/sanitation-schedule/:id', element: <SanitationDetails /> },
    { path: '/dashboard/facility-timer', element: <FacilityTimer /> },
    { path: '/dashboard/facility-timer/:id', element: <FacilityTimerDetails /> },
    { path: '/dashboard/coming-soon', element: <ComingSoon /> },
    { path: '/dashboard/facility-release', element: <FacilityRelease /> },
    { path: '/dashboard/facility-release/:id', element: <FacilityReleaseDetails /> },
    { path: '/dashboard/work-schedule', element: <WorkOrder /> },
    { path: '/dashboard/work-order-page', element: <WorkOrderPage /> },
    { path: '/dashboard/create-work-order', element: <CreateWorkOrder /> },
    { path: '/dashboard/work-order/:locationId', element: <WorkOrderDetails /> },
    { path: '/dashboard/work-order/select-inspector/:id', element: <SelectInspector /> },
    { path: '/dashboard/work-order/facility-work-order/:id', element: <FacilityWorkOrder /> },
    { path: '/dashboard/work-order-facility/:workId', element: <WorkOrderFacility /> },
    { path: '/dashboard/work-order-order/:workId', element: <WorkOrderOrder /> },
    { path: '/dashboard/add-task', element: <AddTask /> },
    { path: '/dashboard/edit-task/:taskId', element: <TaskDetails /> },
    { path: '/dashboard/rooms', element: <Room /> },
    { path: '/dashboard/add-room', element: <AddRoom /> },
    { path: '/dashboard/view-details/:roomId', element: <RoomDetails /> },
    { path: '/dashboard/task-details-page/:taskId', element: <TaskDetailsPage /> },
    { path: '/dashboard/view-room/:roomId', element: <RoomView /> },
    { path: '/dashboard/messages', element: <ChatHome /> },
    { path: '/dashboard/messages/userlist', element: <UserChatList /> },
    { path: '/dashboard/messages/chat/:id', element: <ChatBox /> },
    { path: '/dashboard/locations', element: <Locations /> },
    { path: '/dashboard/attendance', element: <AttendanceHome /> },
    { path: '/dashboard/attendance/history', element: <AttendanceHistory /> },
    { path: '/dashboard/work-history-details/:roomId', element: <WorkHistoryDetails /> },
    { path: '/dashboard/work-history-details/inspector/:insId', element: <InspectorHistoryDetails /> },
    { path: '/dashboard/work-history-details/cleaner/:cleanerId', element: <CleanerHistoryDetails /> },
    {
      path: '/dashboard/work-history',
      element: <WorkHistory />,
      children: [
        { index: true, path: '', element: <RoomHistory /> },
        { path: 'cleaners', element: <CleanerHistory /> },
        { path: 'inspectors', element: <InspectorHistory /> }
      ]
    },
    { path: '/dashboard/evidence', element: <Evidence /> },
    { path: '/dashboard/evidence/view/:taskId', element: <ViewEvidence /> },
    { path: '/dashboard/contact', element: <Contact /> },
    { path: '/dashboard/inventory', element: <Inventory /> },
    { path: '/dashboard/chemical-calculator', element: <ChemicalCalculatorHome /> },
    { path: '/dashboard/chemical-calculator/add', element: <AddChemicalMix /> },
    { path: '/dashboard/chemical-calculator/edit', element: <EditChemicalMix /> },
    { path: '/dashboard/chemical-calculator/view/:id', element: <ViewChemicalMix /> },
    { path: '/dashboard/users', element: <Users /> },
    {
      path: '/dashboard/permissions',
      element: <Permissions />
    },
    { path: '/dashboard/permissions/create', element: <CreatePermissions /> },
    { path: '/dashboard/permissions/assign', element: <AssignPermission /> },
    { path: '/dashboard/roles', element: <Roles /> },
    { path: '/dashboard/roles/create', element: <CreateRole /> },
    { path: '/dashboard/roles/assign', element: <AssignRole /> },
    { path: '/dashboard/roles/permissions/:id', element: <ViewRolePermissions /> },
    { path: '/dashboard/roles/staff', element: <StaffRoles /> },
    { path: '/dashboard/roles/staff/revoke/:staffId', element: <RevokeRole /> },
    { path: '/dashboard/training', element: <Learning /> },
    { path: '/dashboard/training-overview', element: <TrainingOverview /> },
    { path: '/dashboard/training-team', element: <TeamOverview /> },
    { path:'/dashboard/training-category', element: <TrainingCategory />},
    { path: '/dashboard/add-training', element: <CreateTraining /> },
    { path:'dashboard/set-training', element: <SetTraining />},
    { path: '/dashboard/add-resource', element: <AddCourse /> },
    { path: '/dashboard/resource/:id', element: <ViewCourse /> },
    { path: '/dashboard/edit-training/:id', element: <EditTraining /> },
    { path: '/dashboard/view-training/:id', element: <ViewTraining /> },
    { path: '/dashboard/training/:id', element: <Lessons /> },
    { path: '/dashboard/training-files/:id', element: <LessonFile /> },
    { path: '/dashboard/cleaner-management', element: <AdvancedCleanerMgtHome /> },
    { path: '/dashboard/cleaner-management/reviews/:id', element: <ReviewsHome /> },
    // cleaner
    { path: '/dashboard/cleaner/cleaner-location-details/:id', element: <CleanerLocationDetails /> },
    {
      path: `/dashboard/${role}/training`,
      element: <LearningHome />
    },
    { path: '/dashboard/cleaner/cleaner-facility-items-details/:id', element: <CleanerFacilityCleaningItems /> },
    { path: '/dashboard/cleaner/save-cleaning-items/:id', element: <SaveCleaningItems /> },
    { path: '/dashboard/cleaner/cleaner-summary', element: <CleanerSummary /> },
    { path: '/dashboard/cleaner/cleaner-timer/:id', element: <CleanerTimer /> },
    { path: '/dashboard/cleaner/new-cleaner-timer/:id', element: <NewCleanerTimer /> },
    { path: '/dashboard/cleaner/request-cleaning-items', element: <RequestCleaningItems /> },
    { path: '/dashboard/timer', element: <Timer /> },
    { path: '/dashboard/clock-in', element: <ClockInCard /> },
    { path: '/dashboard/inspector/inspector-location-details/:id', element: <InspectorLocationDetails /> },
    { path: '/dashboard/inspector/inspector-facility-items-details/:id', element: <InspectorFacilityCleaningItems /> },
    { path: '/dashboard/inspector/inspector-timer', element: <InspectorTimer /> },
    { path: '/dashboard/inspector/close-work-order', element: <CloseWorkOrder /> },
    { path: '/dashboard/requests', element: <InspectorRequests /> },
    { path: '/dashboard/requests/:id', element: <InspectorRequestsDetails /> },
    { path: '/dashboard/facility-timers', element: <InspectorFacilityTimer /> },
    { path: '/dashboard/facility-timers/:id', element: <InspectorFacilityTimerDetails /> },
    { path: '/dashboard/inspector/inspector-facility-details/:id', element: <InspectorFacilityDetails /> },
    { path: '/dashboard/coming-soon', element: <InspectorComingSoon /> },
    { path: '/dashboard/facility-release', element: <InspectorFacilityRelease /> },
    { path: '/dashboard/facility-release/:id', element: <InspectorFacilityReleaseDetails /> }
  ]
};

export default MainRoutes;
