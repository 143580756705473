import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Container,
  TablePagination,
} from "@mui/material";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";
import { format } from "date-fns";

const ChecklistHome = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { checklists, fetchChecklists, loading } = useAdvancedCleanerMGT();

  useEffect(() => {
    fetchChecklists();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredChecklists = checklists.filter((checklist) => {
    const formattedDate = format(new Date(checklist.dueDate), 'MM/dd/yyyy');
    return (
      checklist.priority.toLowerCase().includes(filter.toLowerCase()) ||
      formattedDate.includes(filter)
    );
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Checklists
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Assigned general to dos for your organization
      </Typography>
      <Box mt={2}>
        <TextField
          variant="outlined"
          label="Filter checklists by priority and date"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChecklists
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((checklist, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{checklist.priority}</TableCell>
                    <TableCell>{checklist.taskDescription}</TableCell>
                    <TableCell>{format(new Date(checklist.dueDate), 'MM/dd/yyyy')}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredChecklists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ChecklistHome;
