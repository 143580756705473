import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Star, StarBorder, CameraAlt, Close } from "@mui/icons-material";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddReview = ({ visible, onClose, selectedCleaner }) => {
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { addReview } = useAdvancedCleanerMGT();

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const newReview = {
      cleaner: selectedCleaner,
      partner: "60d5ec49f4868f45b75e3354",
      rating: rating,
      text: review,
      media: image,
    };

    try {
      await addReview(newReview);
      toast.success("Review added successfully!");
      setImage(null);
      setRating(0);
      setReview("");
      setTitle("");
      onClose();
    } catch (error) {
      toast.error("Error creating review, please try again.");
      console.error("Error creating review:", error);
    } finally {
      setLoading(false);
    }
  };

  const pickImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          toast.success("Image uploaded successfully!");
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>
        Add Review
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Title"
          placeholder="What's most important to know?"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Typography variant="h6" gutterBottom>
          Rating
        </Typography>
        <Box display="flex">
          {[1, 2, 3, 4, 5].map((star) => (
            <IconButton key={star} onClick={() => handleRating(star)}>
              {star <= rating ? <Star color="primary" /> : <StarBorder />}
            </IconButton>
          ))}
        </Box>
        <TextField
          label="Review"
          placeholder="What did you like or dislike about the cleaning procedure?"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={pickImage}
          fullWidth
          startIcon={<CameraAlt />}
        >
          Upload Image
        </Button>
        {image && (
          <Box mt={2} display="flex" justifyContent="center">
            <img
              src={image}
              alt="Review"
              style={{ maxWidth: "100%", borderRadius: 8 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

export default AddReview;
