import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";
import CreateWorkOrderModal from "./CreateWorkOrderModal";

const WorkOrderHome = () => {
  const {
    workOrders,
    fetchWorkOrders,
    cleaners,
    fetchCleaners,
    createWorkOrder,
    loading,
  } = useAdvancedCleanerMGT();
  const [filter, setFilter] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchCleaners();
    fetchWorkOrders();
  }, []);

  const renderStatus = (status) =>
    status === "in_progress"
      ? "In Progress"
      : status === "pending"
      ? "Pending"
      : "Completed";

  const getCleanerName = (cleanerId) => {
    const cleaner = cleaners.find((c) => c._id === cleanerId);
    return cleaner ? `${cleaner.firstname} ${cleaner.lastname}` : "Unknown Cleaner";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredWorkOrders = workOrders.filter(
    (workOrder) =>
      getCleanerName(workOrder.cleaner).toLowerCase().includes(filter.toLowerCase()) ||
      workOrder.location.toLowerCase().includes(filter.toLowerCase()) ||
      workOrder.status.toLowerCase().includes(filter.toLowerCase())
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleWorkOrderCreation = async (newWorkOrder) => {
    await createWorkOrder(newWorkOrder);
    setModalVisible(false);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Welcome Partner!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Keep track of all Cleaning Activities, Work Orders, Cleaners, and Inspectors.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalVisible(true)}
          sx={{ marginBottom: 2 }}
        >
          Create Work Order
        </Button>
        <TextField
          variant="outlined"
          label="Filter by cleaner, location, or status"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cleaner</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWorkOrders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{getCleanerName(item.cleaner)}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>{renderStatus(item.status)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredWorkOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CreateWorkOrderModal
        onClose={() => setModalVisible(false)}
        onCreateWorkOrder={handleWorkOrderCreation}
        visible={modalVisible}
      />
    </Box>
  );
};

export default WorkOrderHome;
