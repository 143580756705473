import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  TablePagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAdvancedCleanerMGT from "Hooks/useAdvancedCleanerMGT";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    padding: '32px',
    backgroundColor: "#fff",
  },
  header: {
    marginBottom: '24px',
  },
  input: {
    marginBottom: '16px',
    width: 300,
  },
  table: {
    marginBottom: '24px',
  },
  evidenceContainer: {
    padding: '24px',
  },
  evidenceImage: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    marginBottom: '16px',
  },
}));

const ComplaintsManagement = () => {
  const classes = useStyles();
  const { complaints, fetchComplaints, loading } = useAdvancedCleanerMGT();
  const [filter, setFilter] = useState("");
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchComplaints();
  }, []);

  const filteredComplaints = complaints.filter(
    (complaint) =>
      complaint.date.includes(filter) || complaint.cleaner.toLowerCase().includes(filter) 
  );

  const openEvidenceDialog = (complaint) => {
    setSelectedComplaint(complaint);
  };

  const closeEvidenceDialog = () => {
    setSelectedComplaint(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.header} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Complaints Management
        </Typography>
        <TextField
          className={classes.input}
          label="Filter by type or cleaner"
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Cleaner</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredComplaints.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((complaint) => (
              <TableRow key={complaint.id}>
                <TableCell>{complaint.description}</TableCell>
                <TableCell>{complaint.cleaner}</TableCell>
                <TableCell>{complaint.date}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openEvidenceDialog(complaint)}
                  >
                    View Evidence
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredComplaints.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={!!selectedComplaint} onClose={closeEvidenceDialog}>
        <DialogTitle>
          Evidence Details
          <IconButton
            aria-label="close"
            onClick={closeEvidenceDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.evidenceContainer}>
          {selectedComplaint && (
            <>
              <img
                src={selectedComplaint.evidence.image}
                alt="Evidence"
                className={classes.evidenceImage}
              />
              {/* Uncomment and use the following line if you have a video */}
              {/* <video src={selectedComplaint.evidence.video} className={classes.evidenceVideo} controls /> */}
              <Typography variant="body1">
                Timestamp: {selectedComplaint.evidence.timestamp}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEvidenceDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ComplaintsManagement;
