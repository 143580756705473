import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  TablePagination
} from '@mui/material';
import { Info as InfoIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import useChemicalMix from 'Hooks/useChemicalMix';

const ChemicalCalculatorHome = () => {
  const navigate = useNavigate();
  const { getAllChemicalMixes, loading, chemicalMixes } = useChemicalMix();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getAllChemicalMixes();
  }, []);

  const filteredChemicalMixes = chemicalMixes.filter(
    chemical => chemical.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
        <Box>
          <Typography variant="h3" color={'blue'} fontWeight={'bold'}>
            Chemical Calculator
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Set and calculate chemical mix within the platform.
          </Typography>
        </Box>
        <Button onClick={() => navigate('/dashboard/chemical-calculator/add')} variant="contained" style={{ backgroundColor: 'blue' }}>
          Set Chemical Mix
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <TextField
        label="Search Chemical Mixes"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Chemical Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredChemicalMixes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((chemical, index) => (
                  <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 123, 255, 0.1)' : 'inherit' }}>
                    <TableCell>{chemical.name}</TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => navigate(`/dashboard/chemical-calculator/view/${chemical._id}`)}>
                          <InfoIcon />
                        </IconButton>
                        <IconButton onClick={() => navigate('/dashboard/chemical-calculator/edit', { state: chemical })}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredChemicalMixes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Container>
  );
};

export default ChemicalCalculatorHome;
