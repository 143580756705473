import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Avatar,
  Stack,
  Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useAttendance from 'Hooks/useAttendance';
import BackButton from 'component/BackButton';

const AttendanceHistory = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { attendanceHistory, getAttendanceHistory, loading } = useAttendance();

  useEffect(() => {
    getAttendanceHistory();
  }, []);

  useEffect(() => {
    // Flatten the attendance data
    const flattenedData = attendanceHistory.flatMap(item =>
      item.attendance.map(record => ({
        ...record,
        userId: item.userId,
        roleId: item.roleId
      }))
    );
    setFilteredData(flattenedData);
  }, [attendanceHistory]);

  const handleSearch = event => {
    const query = event.target.value;
    setSearchQuery(query);
    const filteredList = attendanceHistory
      .flatMap(item =>
        item.attendance.map(record => ({
          ...record,
          userId: item.userId,
          roleId: item.roleId
        }))
      )
      .filter(item => item.userId.username.toLowerCase().includes(query.toLowerCase()));
    setFilteredData(filteredList);
  };

  const formatTime = dateString => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString([], options);
  };

  const startIndex = (page - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <BackButton />
        </Box>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
          <Box>
            <Typography variant="h4" fontWeight={'bold'}>
              Attendance History
            </Typography>
            <Typography variant="body2" color="textSecondary">
              All Employees Attendance
            </Typography>
          </Box>

          <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, border: 'solid 1px blue' }}>
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" value={searchQuery} onChange={handleSearch} />
              <IconButton type="button" sx={{ p: '10px' }}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Divider />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ border: 'solid 1px blue', borderRadius: 10, marginTop: '1em' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Facility Name</TableCell>
                <TableCell>Clock In Time</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((record, index) => (
                <TableRow key={record._id}>
                  <TableCell>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar alt={record.userId.username} src={`/images/avatar${index + 1}.jpg`} />
                      <Typography>{record.userId.username}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{record.roleId.role_name}</TableCell>
                  <TableCell>{record.facilityId.facility_name}</TableCell>
                  <TableCell>{formatTime(record.clockInTime)}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color={record.status === 'on_time' ? 'success' : 'error'}>
                      {record.status === 'on_time' ? 'On Time' : 'Late'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Showing{' '}
            <Select value={recordsPerPage} onChange={e => setRecordsPerPage(e.target.value)} sx={{ mx: 1 }}>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>{' '}
            of {filteredData.length} records
          </Typography>
        </Box>
        <Pagination
          count={Math.ceil(filteredData.length / recordsPerPage)}
          page={page}
          onChange={(e, value) => setPage(value)}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Container>
  );
};

export default AttendanceHistory;
