import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress'; // For showing a loading indicator
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, setIsLoggedIn } = useAuthState(); // Assuming useAuthState provides an isLoading flag
  const [authChecked, setAuthChecked] = useState(false);
  const location = useLocation(); // To capture the current location
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [redirected, setRedirected] = useState(false); // Track if user has been redirected
  const token = localStorage.getItem('auth-token');

  useEffect(() => {
    const checkToken = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        setIsExpired(decodedToken.exp < currentTime);
      } catch (error) {
        console.error('Invalid token:', error);
        setIsExpired(true); // Consider invalid tokens as expired
      }
    };

    checkToken();
  }, [token]);

  useEffect(() => {
    // Simulate an async check to auth state (if needed)
    const checkAuthState = async () => {
      // Wait for auth state to be confirmed
      // This can be an actual check or simply waiting for a flag
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Example delay to simulate loading
      setAuthChecked(true);
      setIsLoading(false);
    };
    checkAuthState();
  }, []);

  useEffect(() => {
    if (isExpired && !redirected) {
      alert('Token has expired and is now invalid. Redirecting to login...');
      setIsLoggedIn(false)
      // localStorage.clear()
      setRedirected(true);
    }
  }, [isExpired, redirected]);

  if (isExpired && redirected) {
    return <Navigate to="/" replace />;
  }

  if (!authChecked || isLoading) {
    // Check for isLoading if it's provided by your auth context
    // Show a loading indicator while authentication status is being confirmed
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!isLoggedIn) {
    // Redirect them to the /login page, saving the current location they were trying to go to
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
