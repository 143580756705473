import React, { useState } from 'react';
import { Box, Tab, styled, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import WorkOrderHome from './Work Order/WorkOrderHome';
import ComplaintsManagement from './Complaints/ComplaintsHome';
import ChecklistsHome from './Checklists/ChecklistsHome';
import UsersHome from './Users/UsersHome';

const StyledTabList = styled(TabList)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 5
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '25%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f0f0f0',
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: 'white',
    color: 'blue'
  }
}));

const AdvancedCleanerMgtHome = () => {
  // Initialize tab value from localStorage or default to '1'
  const [tabValue, setTabValue] = useState(localStorage.getItem('tabValue') || '1');

  // Update tab value and store it in localStorage
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('tabValue', newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, m: 2, minHeight: '100vh', padding: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4em' }}>
        <Box>
          <Typography variant="h3" component="h2" color="blue">
            Advanced Cleaner Management System
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Manage and oversee work orders, complaints, checklists, and commendations.
          </Typography>
        </Box>
      </Box>

      <TabContext value={tabValue}>
        <StyledTabList onChange={handleChange}>
          <StyledTab label="Work Orders" value="1" />
          <StyledTab label="Complaints" value="2" />
          <StyledTab label="Checklists" value="3" />
          <StyledTab label="Commendations" value="4" />
        </StyledTabList>
        <TabPanel value="1">
          <WorkOrderHome />
        </TabPanel>
        <TabPanel value="2">
          <ComplaintsManagement />
        </TabPanel>
        <TabPanel value="3">
          <ChecklistsHome />
        </TabPanel>
        <TabPanel value="4">
          <UsersHome />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AdvancedCleanerMgtHome;
