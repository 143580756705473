import {
  useState
} from 'react';
import axios from 'axios';
import {
  datadogLogs
} from '@datadog/browser-logs';

const useAttendance = () => {
  const [attendanceList, setAttendanceList] = useState([]); // State variable used to store all users in attendance
  const [attendanceHistory, setAttendanceHistory] = useState([])
  const [isLoading, setIisLoading] = useState(false)

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const access_token = localStorage.getItem('auth-token');

  const getAttendance = async () => {
    setIisLoading(true)
    try {
      const response = await axios.get(`${BASE_URL}staff-attendance`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data);
      setAttendanceList(response.data.data)
      setIisLoading(false)
    } catch (error) {
      datadogLogs.logger.error('An Unknown Error occurred', {}, error)
      setIisLoading(false)
    }
  };

  const getAttendanceHistory = async () => {
    setIisLoading(true)
    try {
      const response = await axios.get(`${BASE_URL}staff-attendance/attendance-history`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data);
      setAttendanceHistory(response.data.data)
    } catch (error) {
      console.log(error)
    }
    setIisLoading(false)
  }

  return {
    getAttendance,
    attendanceList,
    getAttendanceHistory,
    attendanceHistory,
    isLoading
  };
};

export default useAttendance;